import {
  isImageStep,
  isMediaStep,
  isNonEmptyStep,
  isOverlayStep,
  isOverlayStepWithPaths,
} from '@arcadehq/shared/helpers'
import {
  FlowDataDoc,
  FlowWrapper,
  PublishStatus,
  Step,
  StepType,
} from '@arcadehq/shared/types'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { Flow, FlowData, FlowPublicData } from 'src/types'

import {
  getFromSerialized,
  getSerializable,
  SerializableData,
} from './serializable'
import { getStepPreviewUrl } from './steps'

export function getSerializablePublicFlow(
  flow: Flow
): SerializableData<FlowPublicData> {
  return getSerializable({
    ...pick(flow, [
      'id',
      'created',
      'modified',
      'name',
      'description',
      'aspectRatio',
      'cta',
      'steps',
      'createdBy',
      'editors',
      'status',
      'submittedForSpotlight',
      'flowWrapper',
      'font',
      'bgImage',
      'group',
      'showArcadeButton',
      'openingAnimation',
      'belongsToTeam',
      'showFlowNavIndicator',
      'optimizeFlowForMobileView',
      'showStartOverlay',
      'startOverlayButtonText',
      'preventIndexing',
      'structureHash',
      'structureBits',
      'autoplay',
      'backgroundMusicUrl',
      'customVariables',
      'thumbnailUrl',
      'showCaptions',
    ]),
    // Don't leak the actual timestamp but "anonymize" it to 1970-01-01
    firstViewed: flow.firstViewed ? new Date(0) : null,
    steps: flow.steps.map((step): Step => {
      const base = {
        id: step.id,
        hiddenFromNavigation: step.hiddenFromNavigation,
      }
      if (!isNonEmptyStep(step)) {
        return { ...base, type: step.type }
      }
      const nonEmptyBase = {
        ...base,
        syntheticVoice: step.syntheticVoice,
        cta: step.cta,
      }
      if (isOverlayStep(step)) {
        const overlayBase = {
          ...nonEmptyBase,
          type: step.type,
          title: step.title,
          subtitle: step.subtitle,
          blur: step.blur,
          theme: step.theme,
        }
        if (isOverlayStepWithPaths(step)) {
          return { ...overlayBase, paths: step.paths, type: step.type }
        } else {
          return {
            ...overlayBase,
            buttonText: step.buttonText,
            buttonType: step.buttonType,
            buttonColor: step.buttonColor,
            buttonTextColor: step.buttonTextColor,
            actionUrl: step.actionUrl,
          }
        }
      }
      const mediaBase = {
        ...nonEmptyBase,
        url: step.url,
        size: step.size,
        panAndZoom: step.panAndZoom,
      }
      if (isImageStep(step)) {
        return {
          ...mediaBase,
          type: step.type,
          originalImageUrl: step.url, // No need to expose any other url in public flow data
          hotspots: step.hotspots,
          blurhash: step.blurhash,
        }
      }
      return {
        ...mediaBase,
        type: step.type,
        targetId: step.targetId,
        streamUrl: step.streamUrl,
        videoThumbnailUrl: step.videoThumbnailUrl,
        playbackRate: step.playbackRate,
        assetId: step.assetId,
        startTimeFrac: step.startTimeFrac,
        endTimeFrac: step.endTimeFrac,
        duration: step.duration,
        muted: step.muted,
        videoProcessing: step.videoProcessing,
      }
    }),
  })
}

export function getFlowFromSerializablePublicFlow(
  publicFlow: SerializableData<FlowPublicData>
): Flow {
  const deserializedFlow = getFromSerialized<FlowPublicData>(publicFlow)
  return {
    ...deserializedFlow,
    schemaVersion: '',
    experiments: [],
    experimentsConfig: {},
    gifUrl: '',
    videoUrl: '',
    tagIds: [],
    folderId: null,
    publishedDate: new Date(),
    lastModifiedBy: '',
    uploadId: '',
    unlocked: null,
    // Don't leak the actual timestamp but "anonymize" it to 1970-01-01
    firstViewed: deserializedFlow.firstViewed ? new Date(0) : null,
    update: async (entity: Partial<FlowData>, userId: string | null) => {
      if (!userId) return false
      // ensure firebase is not bundled in the viewer
      const { updateFlow } = await import('src/store/flows')
      return updateFlow(deserializedFlow.id, entity, userId)
    },
  }
}

// TODO: Ideally we should share the `Flow` type between apps
// and not have to do this.
export function getFlowDataDocFromFlow(flow: Flow): FlowDataDoc {
  return {
    ...omit(flow, ['id', 'created', 'modified', 'createdBy', 'lastModifiedBy']),
  }
}

export function getEmptyFlow(data: Partial<Flow> = {}): Flow {
  return {
    id: '',
    name: '',
    description: '',
    schemaVersion: '',
    uploadId: '',
    aspectRatio: 1,
    steps: [],
    cta: {},
    editors: [],
    status: PublishStatus.draft,
    submittedForSpotlight: false,
    flowWrapper: FlowWrapper.browserLight,
    font: '',
    bgImage: null,
    group: '',
    experiments: [],
    experimentsConfig: {},
    showArcadeButton: false,
    openingAnimation: null,
    belongsToTeam: false,
    showFlowNavIndicator: false,
    optimizeFlowForMobileView: false,
    showStartOverlay: false,
    startOverlayButtonText: '',
    preventIndexing: false,
    structureHash: '',
    structureBits: undefined,
    autoplay: false,
    publishedDate: undefined,
    createdBy: '',
    lastModifiedBy: '',
    created: new Date(),
    modified: new Date(),
    gifUrl: '',
    videoUrl: '',
    tagIds: [],
    folderId: null,
    backgroundMusicUrl: '',
    firstViewed: null,
    unlocked: null,
    customVariables: null,
    thumbnailUrl: null,
    showCaptions: true,
    update: async () => false,
    delete: async () => {},
    ...data,
  }
}

export function flowHasAudio(flow: Flow) {
  return (
    flow.backgroundMusicUrl !== '' ||
    flow.steps.some(step => step.type === StepType.Video && !step.muted) ||
    flowHasVoiceover(flow)
  )
}

export function flowHasVoiceover(flow: Flow) {
  return flow.steps.some(
    step => isNonEmptyStep(step) && step.syntheticVoice?.url
  )
}

export function isOnLastStep(flow: Flow, currentStepId: string) {
  return flow.steps[flow.steps.length - 1]?.id === currentStepId
}

export function getFlowThumbnailUrl(flow: FlowData) {
  return flow.thumbnailUrl ?? getStepPreviewUrl(flow.steps.find(isMediaStep))
}
